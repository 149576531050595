@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0;
  background-color: #000;
  overflow: hidden;
}

canvas {
  display: block;
}

@keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.animate-flicker {
  animation: flicker 1.5s infinite alternate;
}

.text-hacker-green {
  color: #00ff00;
}
